import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const AtlanticUrologyLandingPage = () => (
  <PhysicianLandingPage
    physician="Atlantic Urology"
    institution="Atlantic Urology"
    referralCode="ATLANTICUROLOGY"
    physicianURL="https://atlanticurologyclinics.com/"
  />
)

export default AtlanticUrologyLandingPage
